<template>
    <div class="">
        <h5>General</h5>
        <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" v-model="settings.saveActiveTabsInMemory" role="switch" id="flexSwitch_saveActiveTabsInMemory">
            <label class="form-check-label" for="flexSwitch_saveActiveTabsInMemory">Save Active Tabs In Memory</label>
            <button type="button" class="btn btn-sm btn-link" v-popover data-bs-toggle="popover" data-bs-trigger="focus" data-bs-html="true" data-bs-content="Active tabs will be saved in localstorage and will be shown after refresh.<br/><br/><strong>NB: </strong>This does not save modified content in DB Objects, only custom queries.">
                    <i class="bi bi-question-circle"></i>
            </button>
        </div>
        <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" v-model="settings.autoCloseInactiveTab" role="switch" id="flexSwitch_autoCloseInactiveTab" disabled>
            <label class="form-check-label" for="flexSwitch_autoCloseInactiveTab">Auto Close Inactive Tabs</label>
            <button type="button" class="btn btn-sm btn-link" v-popover data-bs-toggle="popover" data-bs-trigger="focus" data-bs-content="If there was no change done in code of the tab it will close when new tab is opened.">
                    <i class="bi bi-question-circle"></i>
            </button>
        </div>
        <div class="form-check form-switch d-none">
            <input class="form-check-input" type="checkbox" v-model="settings.showOpenEditors" role="switch" id="flexSwitch_showOpenEditors">
            <label class="form-check-label" for="flexSwitch_showOpenEditors">Show Open Editors</label>
            <button type="button" class="btn btn-sm btn-link" v-popover data-bs-toggle="popover" data-bs-trigger="focus" data-bs-content="When not active tabs are not auto closed, inactive tabs will be left in open editors panel.">
                    <i class="bi bi-question-circle"></i>
            </button>
        </div>
        <h5>Query</h5>
        <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" v-model="settings.autoComplete" @change="handleAutocompleteChange" role="switch" id="flexSwitch_autocomplete">
            <label class="form-check-label" for="flexSwitch_autocomplete">Enable Auto Complete</label>
        </div>
        <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" v-model="settings.notepadMode" @change="handleAutocompleteChange" role="switch" id="flexSwitch_notepadMode">
            <label class="form-check-label" for="flexSwitch_notepadMode" title="Will disable all monaco feautures">Notepad mode </label>
        </div>
        <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" v-model="settings.quickFix" role="switch" id="flexSwitch_quickfix">
            <label class="form-check-label" for="flexSwitch_quickfix">QuickFix</label>
        </div>
        <h6>Show System Columns</h6> 
        <div class="form-check form-switch" v-for="col in settings.systemColumnsToshow">
            <input class="form-check-input" type="checkbox" v-model="col.show" role="switch" :id="'flexSwitch_autohide_'+col.name">
            <label class="form-check-label" :for="'flexSwitch_autohide_'+col.name">{{col.name}}</label>
        </div>
        <h6>Results grid</h6>
        <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" v-model="settings.hideFilterRow" role="switch" id="flexSwitch_hideFilterRow">
            <label class="form-check-label" for="flexSwitch_hideFilterRow">Hide Filter Row</label>
        </div>
        <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" v-model="settings.hideGridMenu" role="switch" id="flexSwitch_hideGridMenu">
            <label class="form-check-label" for="flexSwitch_hideGridMenu">Hide Grid Menu</label>
        </div>
    <!--    <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" v-model="settings.autoEnableApiMode" role="switch" id="flexSwitch_autoEnableApiMode">
            <label class="form-check-label" for="flexSwitch_autoEnableApiMode">Auto Enable Api Mode (Other options avalaible under grid menu)</label>
            <button type="button" class="btn btn-sm btn-link" v-popover data-bs-toggle="popover" data-bs-trigger="focus" data-bs-content="If results data object has PrimKey and view is set, api mode allows to filter, update and delete records live.">
                    <i class="bi bi-question-circle"></i>
            </button>
        </div>-->

        
    </div>
</template>
<script setup>
    import {getSettings} from 'DbTools.modules.DBManagerSettings.ts';
    import vPopover from 'o365.vue.directive.popover.js';
    const settings = getSettings();

    const handleAutocompleteChange= () => {
        location.reload()
    }

</script>